import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
} from "react-router-dom";
import { NavMenu } from './components/navmenu';
import { Auth } from './components/auth';
import { Album } from './components/album';

const root = document.getElementById('root');


const AlbumPage: React.FunctionComponent<{}> = props => {
    const params = useParams<{ id: string; }>();
    console.log('albumpage', params);
    return <Album {...params} />
}

const AuthPage: React.FunctionComponent<{}> = props => {
    const params = useParams<{ type: string; }>();
    return <Auth {...params} />
}

const App: React.FunctionComponent<{}> = props => {
    return <>
        <div className="root">
            <NavMenu />
            <div className="content">
                <Switch>
                    <Route path="/authentication/:type">
                        <AuthPage />
                    </Route>
                    <Route path="/about">
                        <h2>About</h2>
                    </Route>
                    <Route path="/album/:id">
                        <AlbumPage />
                    </Route>
                    <Route path="/users">
                        <h2>Users</h2>
                    </Route>
                    <Route path="/">
                        <h1>Home</h1>
                    </Route>
                </Switch>
            </div>
        </div>
    </>;
};

ReactDOM.render(
    <Router>
        <App />
    </Router>, root);